// import { decodeBlurHash } from 'fast-blurhash' // https://github.com/mad-gooze/fast-blurhash
import Alpine from 'alpinejs'
import { lazyLoad } from 'unlazy'
import './plugins/scroll-fx'

window.Alpine = Alpine

Alpine.start();

lazyLoad()

const STICKY_OFFSET = 160;

document.addEventListener("htmx:after-swap", (event) => {
  if (!(event.target instanceof HTMLElement)) {
    return;
  }

  window.scrollTo(0, event.target.offsetTop - STICKY_OFFSET);
  const scrollFx = event.target.getElementsByClassName('js-scroll-fx');
      for(var i = 0; i < scrollFx.length; i++) {
        (function(i){
          var scrollableElement = scrollFx[i].getAttribute('data-scrollable-element');
          new ScrollFx(scrollFx[i], scrollableElement);
        })(i);
      }
  lazyLoad()
});
